<template>
  <v-row>
    <v-col class="mt-2" cols="12">
      <v-col class="shades white pa-6">
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <h3>홍보 채널 정보를 설정해주세요.</h3>
          </v-col>
          <!--          <v-col cols="12" md="9" class="mt-6" v-if="type.length > 0">-->
          <!--            <div class="label">광고 시작 예정일</div>-->
          <!--            <v-menu ref="menu" :close-on-content-click="false" v-model="menu" :nudge-right="40"-->
          <!--                    lazy transition="scale-transition" offset-y min-width="290px">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <v-text-field v-on="on" v-model="dateFormatted" prepend-icon="event"-->
          <!--                              @blur="channel['startDate'] = parseDate(dateFormatted)" style="max-width: 200px"-->
          <!--                ></v-text-field>-->
          <!--              </template>-->
          <!--              <v-date-picker v-model="channel.startDate" locale="ko-kr" :min="$moment.tz('Asia/Seoul').add(1, 'day').format('YYYY-MM-DD')" no-title scrollable @input="menu = false">-->
          <!--                &lt;!&ndash;<v-spacer></v-spacer>&ndash;&gt;-->
          <!--                &lt;!&ndash;<v-btn flat color="primary" @click="menu = false">취소</v-btn>&ndash;&gt;-->
          <!--                &lt;!&ndash;<v-btn flat color="primary" @click="$refs.menu.save(startDate)">확인</v-btn>&ndash;&gt;-->
          <!--              </v-date-picker>-->
          <!--            </v-menu>-->
          <!--          </v-col>-->
          <!--          <v-col class="mt-2" cols="12" md="12" v-if="type.length > 0">-->
          <!--            <span class="caption secondary&#45;&#45;text">광고등록 완료 후에도 광고 시작 예정일은 수정할 수 있습니다.</span>-->
          <!--          </v-col>-->
          <v-col class="mt-2" cols="12" md="10">
            <div class="label mt-6 mb-2">
              홍보 채널과 원하시는 상품 유형을 선택해주세요.
            </div>
            <v-row class="mb-2">
              <v-checkbox
                v-model="type"
                @change="checkDone"
                label="네이버 블로그"
                color="rouge"
                value="naver"
                hide-details
                class="checkbox pl-2 pt-2 mr-2"
              ></v-checkbox>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" md="9" class="mb-4">
            <v-row>
              <v-col cols="12" md="8"> -->
          <!--                <span class="caption secondary&#45;&#45;text" v-if="option.hasOwnProperty('price') && option.price">-->
          <!--                  광고 비용은 월 {{0/*(_.includes(type, 'naver') ? option.price.naver.store/10000 : 0) + (_.includes(type, 'insta') ? option.price.insta.store/10000 : 0)*/}}만원 입니다.-->
          <!--                </span>-->
          <!--                <bulbIcon class="light_bulb"/>-->
          <!--                <div v-if="option.hasOwnProperty('price') && option.price">-->
          <!--                  광고 비용은<br>-->
          <!--                  월 {{0/*(_.includes(type, 'naver') ? option.price.naver.store/10000 : 0) + (_.includes(type, 'insta') ? option.price.insta.store/10000 : 0)*/}}만원 입니다.-->
          <!--                </div>-->
          <!-- </v-col>
            </v-row>
          </v-col> -->
          <v-col cols="12" md="4" class="mt-6">
            <div class="label mb-2">
              할인 적용 최대금액<span class="caption ml-1 rouge--text"
                >*필수</span
              >
            </div>
            <v-text-field
              v-model.number="channel.maxPrice"
              name="maxPrice"
              type="number"
              suffix="원"
              class="text-right"
              :rules="[errors.first('maxPrice') || !errors.has('maxPrice')]"
              @input="checkDone"
              hide-details
              single-line
              outlined
              solo
              flat
            ></v-text-field>
          </v-col>
          <v-col class="mb-2" cols="12" md="12">
            <v-row>
              <v-col cols="12" md="8">
                <span class="caption secondary--text"
                  >식당 등 요식업 - 2인을 기준으로 넉넉하게 이용할 수 있는
                  금액을 입력해주세요.<br />미용실 등 그 외 매장 - 1인을
                  기준으로 넉넉하게 이용할 수 있는 금액을 입력해주세요.</span
                >
                <!--                <bulbIcon class="light_bulb"/>-->
                <!--                <div>-->
                <!--                  할인 적용 최대금액은 2인 기준 넉넉하게<br>-->
                <!--                  이용할 수 있는 금액으로 입력해주시면 됩니다.-->
                <!--                </div>-->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="shades white pa-6 mt-2" v-if="type.indexOf('naver') > -1">
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <h3>네이버 블로그 채널의 할인혜택을 설정해주세요.</h3>
          </v-col>
          <v-col cols="12" md="10" class="mt-6">
            <div class="label mb-2">
              네이버 블로그 후기에 들어가야 하는 필수 키워드를
              설정해주세요.<span class="caption ml-1 rouge--text">*필수</span>
            </div>
            <v-row>
              <v-chip
                v-for="(keyword, index) in form.requiredKeywords"
                :key="index"
                style="height: 40px"
                class="mt-2"
                @click:close="removeRequiredKeyword(index)"
                close
                color="rouge"
                label
                outlined
                >{{ keyword }}</v-chip
              >
              <v-text-field
                v-model="requiredKeyword"
                name="requiredKeyword"
                type="text"
                placeholder="예) 다이어트 샐러드"
                class="mt-2"
                @keyup.enter="addRequiredKeyword"
                single-line
                outlined
                solo
                flat
                hide-details
              ></v-text-field>
              <v-btn color="rouge" @click="addRequiredKeyword" class="plus_btn"
                ><v-icon dark>add</v-icon></v-btn
              >
              <v-col class="my-0" cols="12" md="12">
                <span class="caption rouge--text"
                  >*필수 키워드는 최대 3개까지 설정할 수 있습니다.</span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="10" class="mt-6">
            <div class="label mb-2">
              네이버 블로그 후기에 포함되었으면 하는 선택키워드가 있으신가요?
            </div>
            <v-row>
              <v-chip
                v-for="(keyword, index) in form.keywords"
                :key="index"
                style="height: 40px"
                class="mt-2"
                @click:close="removeKeyword(index)"
                close
                color="rouge"
                label
                outlined
                >{{ keyword }}</v-chip
              >
              <v-text-field
                v-model="keyword"
                name="keyword"
                type="text"
                placeholder="예) 상수동 맛집"
                class="mt-2"
                @keyup.enter="addKeyword"
                single-line
                outlined
                solo
                flat
                hide-details
              ></v-text-field>
              <v-btn color="rouge" @click="addKeyword" class="plus_btn"
                ><v-icon>add</v-icon></v-btn
              >
            </v-row>
          </v-col>
          <v-col cols="12" md="12" v-if="adsData.type === 'S'">
            <v-col cols="12" md="12" class="mt-6">
              <v-radio-group
                v-model="naverDiscountType"
                :mandatory="true"
                class="radio_group"
                @change="checkDone"
                hide-details
              >
                <v-row class="mb-2">
                  <v-radio
                    label="평균 할인율"
                    color="rouge"
                    value="avg"
                    hide-details
                    class="radio pl-2 pt-0 mr-2 mt-1 mb-0"
                  ></v-radio>
                  <v-radio
                    label="높은 할인율"
                    color="rouge"
                    value="high"
                    hide-details
                    class="radio pl-2 pt-0 mr-2 mt-1 mb-0"
                  ></v-radio>
                  <v-radio
                    label="직접 설정"
                    color="rouge"
                    value="manual"
                    hide-details
                    class="radio pl-2 pt-0 mr-2 mt-1 mb-0"
                  ></v-radio>
                </v-row>
              </v-radio-group>
            </v-col>
            <v-col
              class="my-0"
              v-if="naverDiscountType === 'avg'"
              cols="12"
              md="12"
            >
              <span class="caption secondary--text"
                >가맹점 사장님들께서 가장 많이 이용하시는 할인율입니다.</span
              >
            </v-col>
            <v-col
              class="my-0"
              v-else-if="naverDiscountType === 'high'"
              cols="12"
              md="12"
            >
              <span class="caption secondary--text"
                >지방에서 매장을 운영하시거나많은 후기가 필요하신 사장님께서 많이
                이용하시는 할인율입니다.</span
              >
            </v-col>
            <v-col
              class="my-0"
              v-else-if="naverDiscountType === 'manual'"
              cols="12"
              md="12"
            >
              <span class="caption secondary--text"
                >할인율 직접 설정에 어려움이 있으시다면 문의해주세요.</span
              >
            </v-col>
            <v-col cols="12" md="12" class="mt-6">
              <v-row
                :style="
                  $vuetify.breakpoint.mdAndUp ? '' : 'flex-direction: column;'
                "
              >
                <v-col md="3">
                  <div class="mb-2">블랙 등급</div>
                  <v-row>
                    <v-icon size="20" color="black" class="mb-6"
                      >mdi-hexagon</v-icon
                    >
                    <v-col class="ml-2">
                      <v-text-field
                        v-if="naverDiscountType === 'manual'"
                        v-model.number="form.naver.black"
                        name="naverBlack"
                        type="number"
                        suffix="%"
                        class="text-right mr-4"
                        :rules="[
                          errors.first('naverBlack') || !errors.has('naverBlack'),
                        ]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat
                      ></v-text-field>
                      <span v-else
                        >{{ calculateShowGradeDiscounts("black") }}% 할인
                        제공</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="3">
                  <div class="mb-2">레드 등급</div>
                  <v-row>
                    <v-icon size="20" color="red" class="mb-6"
                      >mdi-hexagon</v-icon
                    >
                    <v-col class="ml-2">
                      <v-text-field
                        v-if="naverDiscountType === 'manual'"
                        v-model.number="form.naver.red"
                        name="naverRed"
                        type="number"
                        suffix="%"
                        class="text-right mr-4"
                        :rules="[
                          errors.first('naverRed') || !errors.has('naverRed'),
                        ]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat
                      ></v-text-field>
                      <span v-else
                        >{{ calculateShowGradeDiscounts("red") }}% 할인 제공</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="3">
                  <div class="mb-2">옐로우 등급</div>
                  <v-row>
                    <v-icon size="20" color="yellow" class="mb-6"
                      >mdi-hexagon</v-icon
                    >
                    <v-col class="ml-2">
                      <v-text-field
                        v-if="naverDiscountType === 'manual'"
                        v-model.number="form.naver.yellow"
                        name="naverYellow"
                        type="number"
                        suffix="%"
                        class="text-right mr-4"
                        :rules="[
                          errors.first('naverYellow') ||
                            !errors.has('naverYellow'),
                        ]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat
                      ></v-text-field>
                      <span v-else
                        >{{ calculateShowGradeDiscounts("yellow") }}% 할인
                        제공</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="3">
                  <div class="mb-2">
                    <v-checkbox
                      v-model="form.naver.enableWhite"
                      @change="checkDone"
                      class="ma-0 pa-0 v-checkbox-white__container"
                      hide-details
                      :disabled="!adsData.receiptReviewRequired"
                    >
                      <template v-slot:label>
                        <span
                          :style="{
                            fontSize: '14px',
                            color: !adsData.receiptReviewRequired
                              ? '#C2C2C2'
                              : '#000000DE',
                          }"
                          >화이트 등급</span
                        >
                      </template>
                    </v-checkbox>
                  </div>
                  <v-row>
                    <v-icon
                      size="20"
                      :color="
                        adsData.receiptReviewRequired ? '#757575' : '#C2C2C2'
                      "
                      class="mb-6"
                      >mdi-hexagon-outline</v-icon
                    >
                    <v-col class="ml-2">
                      <v-text-field
                        v-if="naverDiscountType === 'manual'"
                        v-model.number="form.naver.white"
                        name="naverWhite"
                        type="number"
                        suffix="%"
                        :rules="[
                          errors.first('naverWhite') || !errors.has('naverWhite'),
                        ]"
                        :class="{
                          'text-right': true,
                          'mr-4': true,
                          'white-text-field':
                            !adsData.receiptReviewRequired ||
                            !form.naver.enableWhite,
                        }"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat
                        :disabled="
                          !adsData.receiptReviewRequired ||
                          !form.naver.enableWhite
                        "
                      ></v-text-field>
                      <span
                        v-else
                        :style="{
                          color: !adsData.receiptReviewRequired
                            ? '#C2C2C2'
                            : '#000',
                        }"
                        >{{ calculateShowGradeDiscounts("white") }}% 할인
                        제공</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div
                style="
                  margin-bottom: 14px;
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 16px;
                  color: #bd1328;
                "
              >
                *화이트 등급은 네이버 영수증 리뷰만 지원합니다.
              </div>
              <a style="text-decoration: underline" @click="calculate('naver')"
                >회원 방문시 결제금액 미리보기 ></a
              >
            </v-col>
            <!--          <v-col cols="12" md="10" mb-2>-->
            <!--            <v-row>-->
            <!--              <v-col cols="12" md="12" class="estimate">-->
            <!--                <bulbIcon class="light_bulb"/>-->
            <!--                <div>-->
            <!--                  등급별 추천 할인율은 블랙 100%, 레드 70%, 옐로우 30% 입니다.<br>-->
            <!--                  공격적인 마케팅을 원하시는 경우 레드의 할인율을 높이는 것을 추천 드립니다.-->
            <!--                </div>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <!--          </v-col>-->
          </v-col>
          <v-col cols="12" md="12" v-else-if="adsData.type==='A'">
            <v-col cols="11" md="11">
              <div class="label my-2">
                필수미션을 입력해주세요.<a href="https://www.notion.so/mayacrew/7c67b704a9e64004aaa8a5ead1c0ef3a"
                  class="caption text-decoration-underline"
                  target="_blank">필수미션 작성 예시 보기></a>
              </div>
              <v-row v-for="(m, index) in form.naver.mission || []" :key="index">
                <v-textarea v-model="form.naver.mission[index]"
                    name="mission" type="text" class="mt-2" outlined solo flat hide-details></v-textarea>
                <v-btn color="rouge" @click="removeMission(index)" icon text class="plus_btn">
                  <v-icon style="color: #757575">mdi-trash-can-outline</v-icon></v-btn>
              </v-row>
              <v-row>
                <v-textarea v-model="mission" name="mission" type="text" class="mt-2"
                    @keyup.enter="addMission" outlined solo flat hide-details></v-textarea>
                <v-btn color="rouge" @click="addMission" tile class="plus_btn">추가</v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <div class="label my-2">
                할인율<span class="caption ml-1 rouge--text">*필수</span>
              </div>
              <v-col cols="2">
                <v-text-field type="text" class="mt-2" value="100%"
                              single-line outlined solo flat disabled hide-details></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12" md="12">
              <div class="label my-2">
                원고료<span class="caption ml-1 rouge--text">*필수</span>
              </div>
              <v-col cols="4">
                <v-text-field type="number" class="mt-2 text-right" v-model.number="form.diamondPoint" @input="checkDone"
                              suffix="원" single-line outlined solo flat hide-details readonly disabled></v-text-field>
              </v-col>
<!--              <v-col>-->
<!--                <span class="caption primary&#45;&#45;text">적정 원고료는 20,000원~50,000원입니다.</span>-->
<!--              </v-col>-->
            </v-col>
            <v-col cols="12" md="12">
              <v-col cols="12">
                <div class="label mb-2">
                  모집 희망인원<span class="caption ml-1 rouge--text">*필수</span>
                </div>
<!--                <v-checkbox v-model="naver_limit" label="제한 없음" color="rouge" class="check ma-0" height="20px"></v-checkbox>-->
                <v-row v-if="!naver_limit">
                  <v-col class="pa-1" cols="8" md="6">
                    <v-slider v-model="naver_number" @change="checkDone"
                              always-dirty :min="adsData.type === 'A' ? 5 : 10" :max="adsData.type === 'A' ? 50 : 100" :tick-labels="tickLabels" tick-size="0"
                              :step="adsData.type === 'A' ? 5 : 10" show-ticks="always" hide-details
                    ></v-slider>
                  </v-col>
                  <v-col cols="3" class="pl-6">
                    <v-text-field v-model.number="naver_number" class="mt-0" type="number" suffix="명" readonly></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="shades white pa-6 mt-2" v-if="type.indexOf('insta') > -1">
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <h3>인스타그램 채널의 할인혜택을 설정해주세요.</h3>
          </v-col>
          <v-col cols="12" md="10" class="mt-6">
            <div class="label mb-2">
              인스타그램에서 어떤 해시태그로 노출되기를 원하시나요?
            </div>
            <v-row>
              <v-chip
                v-for="(hashtag, index) in form.hashtags"
                :key="index"
                style="height: 40px"
                class="mt-2"
                @click:close="removeHashtag(index)"
                close
                color="rouge"
                label
                outlined
                >{{ "#" + hashtag }}</v-chip
              >
              <span class="warm-grey--text display-1 mx-2 mt-2">#</span>
              <v-text-field
                v-model="hashtag"
                name="hashtag"
                type="text"
                placeholder="예) 상수동 맛집"
                class="mt-2"
                @keyup.enter="addHashtag"
                single-line
                outlined
                solo
                flat
                hide-details
              ></v-text-field>
              <v-btn color="rouge" @click="addHashtag" class="plus_btn"
                ><v-icon>add</v-icon></v-btn
              >
            </v-row>
          </v-col>
          <v-col cols="12" md="12" class="mt-6">
            <div class="label mb-2">등급별 할인율</div>
            <v-radio-group
              v-model="instaDiscountType"
              :mandatory="true"
              class="radio_group"
              @change="checkDone"
              hide-details
            >
              <v-row class="mb-2">
                <v-radio
                  label="평균 할인율"
                  color="rouge"
                  value="avg"
                  hide-details
                  class="radio pl-2 pt-0 mr-2 mt-1 mb-0"
                ></v-radio>
                <v-radio
                  label="높은 할인율"
                  color="rouge"
                  value="high"
                  hide-details
                  class="radio pl-2 pt-0 mr-2 mt-1 mb-0"
                ></v-radio>
                <v-radio
                  label="직접 설정"
                  color="rouge"
                  value="manual"
                  hide-details
                  class="radio pl-2 pt-0 mr-2 mt-1 mb-0"
                ></v-radio>
              </v-row>
            </v-radio-group>
          </v-col>
          <v-col
            class="my-0"
            v-if="instaDiscountType === 'avg'"
            cols="12"
            md="12"
          >
            <span class="caption secondary--text"
              >가맹점 사장님들께서 가장 많이 이용하시는 할인율입니다.</span
            >
          </v-col>
          <v-col
            class="my-0"
            v-else-if="instaDiscountType === 'high'"
            cols="12"
            md="12"
          >
            <span class="caption secondary--text"
              >지방에서 매장을 운영하시거나많은 후기가 필요하신 사장님께서 많이
              이용하시는 할인율입니다.</span
            >
          </v-col>
          <v-col
            class="my-0"
            v-else-if="instaDiscountType === 'manual'"
            cols="12"
            md="12"
          >
            <span class="caption secondary--text"
              >할인율 직접 설정에 어려움이 있으시다면 문의해주세요.</span
            >
          </v-col>
          <v-col cols="12" md="12" class="mt-6">
            <v-row>
              <v-col md="4">
                <div class="mb-2">블랙 등급</div>
                <v-row>
                  <v-icon size="20" color="black" class="mb-6"
                    >mdi-hexagon</v-icon
                  >
                  <v-col class="ml-2" md="6">
                    <v-text-field
                      v-if="instaDiscountType === 'manual'"
                      v-model.number="form.insta.black"
                      name="instaBlack"
                      type="number"
                      suffix="%"
                      class="text-right"
                      :rules="[
                        errors.first('instaBlack') || !errors.has('instaBlack'),
                      ]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat
                    ></v-text-field>
                    <span v-else>{{ form.insta.black }}% 할인 제공</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="4">
                <div class="mb-2">레드 등급</div>
                <v-row>
                  <v-icon size="20" color="red" class="mb-6"
                    >mdi-hexagon</v-icon
                  >
                  <v-col class="ml-2" md="6">
                    <v-text-field
                      v-if="instaDiscountType === 'manual'"
                      v-model.number="form.insta.red"
                      name="instaRed"
                      type="number"
                      suffix="%"
                      class="text-right"
                      :rules="[
                        errors.first('instaRed') || !errors.has('instaRed'),
                      ]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat
                    ></v-text-field>
                    <span v-else>{{ form.insta.red }}% 할인 제공</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="4">
                <div class="mb-2">옐로우 등급</div>
                <v-row>
                  <v-icon size="20" color="yellow" class="mb-6"
                    >mdi-hexagon</v-icon
                  >
                  <v-col class="ml-2" md="6">
                    <v-text-field
                      v-if="instaDiscountType === 'manual'"
                      v-model.number="form.insta.yellow"
                      name="instaYellow"
                      type="number"
                      suffix="%"
                      class="text-right"
                      :rules="[
                        errors.first('instaYellow') ||
                          !errors.has('instaYellow'),
                      ]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat
                    ></v-text-field>
                    <span v-else>{{ form.insta.yellow }}% 할인 제공</span>
                  </v-col>
                </v-row>
              </v-col>
              <a style="text-decoration: underline" @click="calculate('insta')"
                >회원 방문시 결제금액 미리보기 ></a
              >
            </v-row>
          </v-col>
          <!--          <v-col cols="12" md="10" mb-2>-->
          <!--            <v-row>-->
          <!--              <v-col cols="12" md="12" class="estimate">-->
          <!--                <bulbIcon class="light_bulb"/>-->
          <!--                <div>-->
          <!--                  등급별 추천 할인율은 블랙 100%, 레드 70%, 옐로우 30% 입니다.<br>-->
          <!--                  공격적인 마케팅을 원하시는 경우 레드의 할인율을 높이는 것을 추천 드립니다.-->
          <!--                </div>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--          </v-col>-->
        </v-row>
      </v-col>
      <v-col class="shades white pa-6 mt-2" v-if="type.length > 0">
        <v-col class="px-4">
          <v-col cols="12" md="12">
            <h3>혜택 관련 정보를 입력해주세요.</h3>
          </v-col>
          <v-col cols="12" md="10" class="mt-6">
            <div class="label mb-2">
              회원의 이용 품목에 제한을 두고 싶으신가요?
            </div>
            <v-btn-toggle v-model="isLimitation" @change="checkDone" mandatory>
              <v-btn
                :value="false"
                active-class="rouge--text text--rouge v-btn--active"
                class="ma-0"
                style="width: 130px"
                outlined
                >아니오</v-btn
              >
              <v-btn
                :value="true"
                active-class="rouge--text text--rouge v-btn--active"
                class="ma-0"
                style="width: 130px"
                outlined
                >예</v-btn
              >
            </v-btn-toggle>
          </v-col>
          <v-col class="my-0" v-if="isLimitation" cols="12" md="12">
            <span class="caption rouge--text"
              >이용품목에 제한이 있는 경우 회원의 만족도가 떨어질 수
              있습니다.</span
            >
          </v-col>
          <v-col v-if="isLimitation" cols="12" md="10">
            <div class="label mt-6 mb-2">이용 제한</div>
            <v-text-field
              v-model="channel.limitation"
              name="limitation"
              type="text"
              :rules="[errors.first('limitation') || !errors.has('limitation')]"
              hint="예) 주류는 할인이 제공되지 않습니다."
              persistent-hint
              @input="checkDone"
              single-line
              outlined
              solo
              flat
            ></v-text-field>
          </v-col>
          <!--          <v-col v-if="isLimitation" cols="12" md="12" my-2>-->
          <!--            <v-row>-->
          <!--              <v-col cols="12" md="8" class="estimate">-->
          <!--                <bulbIcon class="light_bulb"/>-->
          <!--                <div>-->
          <!--                  이용품목에 제한이 있는 경우<br>-->
          <!--                  방문한 회원의 만족도가 떨어질 수 있습니다.-->
          <!--                </div>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--          </v-col>-->
        </v-col>
      </v-col>
    </v-col>
    <v-row justify="center">
      <v-dialog v-model="calculator.show" persistent max-width="500px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="calculator.show = false"
                >close</v-icon
              ><br />
              <h2 class="mt-2 font-weight-bold headline">
                등급별 할인율 계산해보기
              </h2>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="label mb-6">
                  회원 방문시 예상되는 총 이용금액을 아래에 입력해주세요.
                </div>
                <v-row>
                  <v-col class="pa-0" cols="5" md="6">
                    <div class="py-2 font-weight-bold" style="font-size: 13px">
                      할인 전 이용 금액
                    </div>
                  </v-col>
                  <v-col cols="7" md="6">
                    <v-text-field
                      v-model.number="calculator.estimate"
                      name="estimate"
                      type="number"
                      suffix="원"
                      class="text-right"
                      :rules="[
                        errors.first('estimate') || !errors.has('estimate'),
                      ]"
                      single-line
                      outlined
                      solo
                      flat
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <div class="line mb-6"></div>
              <v-col cols="12">
                <v-row>
                  <v-col class="pa-0" cols="5" md="6">
                    <div class="label py-2" style="font-size: 13px">
                      할인 적용 최대금액
                    </div>
                  </v-col>
                  <v-col cols="7" md="6">
                    <v-text-field
                      v-model.number="calculator.maxDiscount"
                      name="maxDiscount"
                      type="number"
                      suffix="원"
                      class="text-right"
                      :rules="[
                        errors.first('maxDiscount') ||
                          !errors.has('maxDiscount'),
                      ]"
                      single-line
                      outlined
                      solo
                      flat
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="hidden-sm-and-down mx-0" md="3">
                    <div class="label pt-12" style="font-size: 13px">
                      등급별 할인율
                    </div>
                    <div class="label pt-4 mt-4" style="font-size: 13px">
                      등급별 할인금액
                    </div>
                  </v-col>
                  <v-col class="mx-0 px-1" cols="4" md="3">
                    <h5 class="mb-4">블랙 등급</h5>
                    <v-text-field
                      v-model.number="calculator.black"
                      name="calculatorBlack"
                      type="number"
                      suffix="%"
                      class="text-right"
                      :rules="[
                        errors.first('calculatorBlack') ||
                          !errors.has('calculatorBlack'),
                      ]"
                      single-line
                      outlined
                      solo
                      flat
                    ></v-text-field>
                    <div class="label rouge--text" style="font-size: 13px">
                      {{ blackCal ? blackCal.toLocaleString() : 0 }}원
                    </div>
                  </v-col>
                  <v-col class="mx-0 px-1" cols="4" md="3">
                    <h5 class="mb-4">레드 등급</h5>
                    <v-text-field
                      v-model.number="calculator.red"
                      name="calculatorRed"
                      type="number"
                      suffix="%"
                      class="text-right"
                      :rules="[
                        errors.first('calculatorRed') ||
                          !errors.has('calculatorRed'),
                      ]"
                      single-line
                      outlined
                      solo
                      flat
                    ></v-text-field>
                    <div class="label rouge--text" style="font-size: 13px">
                      {{ redCal ? redCal.toLocaleString() : 0 }}원
                    </div>
                  </v-col>
                  <v-col class="mx-0 pl-1" cols="4" md="3">
                    <h5 class="mb-4">옐로우 등급</h5>
                    <v-text-field
                      v-model.number="calculator.yellow"
                      name="calculatorYellow"
                      type="number"
                      suffix="%"
                      class="text-right"
                      :rules="[
                        errors.first('calculatorYellow') ||
                          !errors.has('calculatorYellow'),
                      ]"
                      single-line
                      outlined
                      solo
                      flat
                    ></v-text-field>
                    <div class="label rouge--text" style="font-size: 13px">
                      {{ yellowCal ? yellowCal.toLocaleString() : 0 }}원
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <div class="line my-6"></div>
              <v-row>
                <v-col class="hidden-sm-and-down mx-0" md="3">
                  <div
                    class="pt-1 label font-weight-bold"
                    style="font-size: 13px"
                  >
                    등급별 결제금액
                  </div>
                </v-col>
                <v-col class="mx-0 px-1" md="3">
                  <div class="label" style="font-size: 18px">
                    {{ (calculator.estimate - blackCal).toLocaleString() }}원
                  </div>
                </v-col>
                <v-col class="mx-0 px-1" md="3">
                  <div class="label" style="font-size: 18px">
                    {{ (calculator.estimate - redCal).toLocaleString() }}원
                  </div>
                </v-col>
                <v-col class="mx-0 pl-1" md="3">
                  <div class="label" style="font-size: 18px">
                    {{ (calculator.estimate - yellowCal).toLocaleString() }}원
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <!--<v-spacer></v-spacer>-->
            <v-btn
              class="rouge white-two--text"
              flat
              @click.native="calculator.show = false"
              >닫기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-row>
</template>
<script>
export default {
  name: "Page4",
  props: ["adsData", "option"],
  data: function () {
    return {
      MAX_REQUIRED_KEYWORD_COUNT: 3,
      form: {
        keywords: this.adsData.keywords || [],
        hashtags: this.adsData.hashtags || [],
        requiredKeywords: this.adsData.requiredKeywords || [],
        diamondPoint: this.adsData.diamondPoint || 20000,
        blackPoint: this.adsData.blackPoint || 20000,
        redPoint: this.adsData.redPoint || 20000,
        naver: {
          enableWhite: false,
        },
        insta: {},
      },
      channel: {
        startDate:
          (this.adsData.naver || {}).startDate ||
          (this.adsData.insta || {}).startDate ||
          this.$moment.tz("Asia/Seoul").add(1, "day").format("YYYY-MM-DD"),
        limitation:
          (this.adsData.naver || {}).limitation ||
          (this.adsData.insta || {}).limitation ||
          "",
        maxPrice:
          Math.max(
            (this.adsData.naver || {}).blackPrice,
            (this.adsData.naver || {}).redPrice,
            (this.adsData.naver || {}).yellowPrice,
            (this.adsData.naver || {}).diamondPrice,
            (this.adsData.insta || {}).blackPrice,
            (this.adsData.insta || {}).redPrice,
            (this.adsData.insta || {}).yellowPrice,
            (this.adsData.insta || {}).diamondPrice
          ) || 0,
      },
      menu: false,
      // dateFormatted: this.formatDate((this.adsData.naver || {}).startDate) || this.formatDate((this.adsData.insta || {}).startDate) || null,
      isLimitation:
        !!(this.adsData.naver || {}).limitation ||
        !!(this.adsData.insta || {}).limitation ||
        false,
      type: ["naver"],
      calculator: {
        show: false,
        estimate: 0,
        maxDiscount: 50000,
        black: 100,
        red: 70,
        yellow: 30,
      },
      keyword: "",
      requiredKeyword: "",
      hashtag: "",
      naverDiscountType: "avg",
      instaDiscountType: "avg",
      mission: "",
      naver_number: (this.adsData.naver || {}).number || 20,
      naver_limit: (this.adsData.naver || {}).number === 0 || false,
    };
  },
  computed: {
    blackCal() {
      let discount =
        this.calculator.estimate > this.calculator.maxDiscount
          ? this.calculator.maxDiscount
          : this.calculator.estimate;
      return discount * this.calculator.black * 0.01;
    },
    redCal() {
      let discount =
        this.calculator.estimate > this.calculator.maxDiscount
          ? this.calculator.maxDiscount
          : this.calculator.estimate;
      return discount * this.calculator.red * 0.01;
    },
    yellowCal() {
      let discount =
        this.calculator.estimate > this.calculator.maxDiscount
          ? this.calculator.maxDiscount
          : this.calculator.estimate;
      return discount * this.calculator.yellow * 0.01;
    },
    tickLabels() {
      if (this.adsData.type === 'A') {
        return Array(10)
            .fill()
            .map((v, i) => {
              if ([5, 25, 50].indexOf((i + 1) * 5) >= 0) {
                return (i + 1) * 5;
              } else return '';
            });
      }
      else {
        return Array(10)
            .fill()
            .map((v, i) => {
              if ([10, 50, 100].indexOf((i + 1) * 10) >= 0) {
                return (i + 1) * 10;
              } else return '';
            });
      }
    },
  },
  watch: {
    "adsData.receiptReviewRequired": function () {
      this.form.naver.enableWhite = this.adsData.receiptReviewRequired;
      this.form.naver.white = this.adsData.receiptReviewRequired ? 10 : null;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}. ${month}. ${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split(". ");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    calculate: function (channel) {
      this.calculator.show = true;
      this.calculator.maxDiscount = this.channel.maxPrice;
      this.calculator.black = this.form[channel].black;
      this.calculator.red = this.form[channel].red;
      this.calculator.yellow = this.form[channel].yellow;
    },
    addRequiredKeyword: function () {
      if (
        this.requiredKeyword.length > 0 &&
        this.form.requiredKeywords.indexOf(this.requiredKeyword) === -1 &&
        this.MAX_REQUIRED_KEYWORD_COUNT > this.form.requiredKeywords.length
      ) {
        this.form.requiredKeywords.push(this.requiredKeyword);
        this.requiredKeyword = "";
        this.checkDone();
      }
    },
    removeRequiredKeyword: function (idx) {
      console.log("remove" + idx);
      this.form.requiredKeywords.splice(idx, 1);
      this.checkDone();
    },
    addKeyword: function () {
      if (
        this.keyword.length > 0 &&
        this.form.keywords.indexOf(this.keyword) === -1
      ) {
        this.form.keywords.push(this.keyword);
        this.keyword = "";
        this.checkDone();
      }
    },
    removeKeyword: function (idx) {
      console.log("remove" + idx);
      this.form.keywords.splice(idx, 1);
      this.checkDone();
    },
    addHashtag: function () {
      if (
        this.hashtag.length > 0 &&
        this.form.hashtags.indexOf(this.hashtag) === -1
      ) {
        this.form.hashtags.push(this.hashtag);
        this.hashtag = "";
        this.checkDone();
      }
    },
    removeHashtag: function (idx) {
      console.log("remove" + idx);
      this.form.hashtags.splice(idx, 1);
      this.checkDone();
    },
    calculateShowGradeDiscounts(grade) {
      if (this.naverDiscountType === "manual") {
        const map = {
          black: this.form.naver.black || 100,
          red: this.form.naver.red || 60,
          yellow: this.form.naver.yellow || 30,
          white: this.form.naver.white || 10,
        };

        return map[grade];
      }

      //'서울' 또는 '그 외 지역'
      const location =
        (this.adsData?.local?.region ?? "서울") === "서울"
          ? "서울"
          : "그 외 지역";

      const discountMaps = {
        avg: {
          서울: {
            black: 100,
            red: 70,
            yellow: 30,
            white: 10,
          },
          "그 외 지역": {
            black: 100,
            red: 100,
            yellow: 50,
            white: 10,
          },
        },
        high: {
          서울: {
            black: 100,
            red: 100,
            yellow: 30,
            white: 10,
          },
          "그 외 지역": {
            black: 100,
            red: 100,
            yellow: 50,
            white: 10,
          },
        },
      };

      return discountMaps[this.naverDiscountType][location][grade];
    },
    addMission: function () {
      this.form.naver.mission = this.form.naver.mission || [];
      if (
          this.mission.length > 0 &&
          this.form.naver.mission.indexOf(this.mission) === -1
      ) {
        this.form.naver.mission.push(this.mission);
        this.mission = "";
        this.checkDone();
      }
    },
    removeMission: function (idx) {
      console.log("remove" + idx);
      this.form.naver.mission.splice(idx, 1);
      this.checkDone();
    },
    checkDone() {
      return this.$validator.validateAll().then((success) => {
        if (success) {
          ["insta", "naver"].map((channel) => {
            if (this._.includes(this.type, channel)) {
              this.form[channel] = this.form[channel] || {};
              this.form[channel].startDate = this.channel.startDate;
              this.form[channel].limitation = this.channel.limitation;
              if (this[channel + "DiscountType"] === "avg") {
                if (this.adsData.local.region === "서울") {
                  this.form[channel].black = channel === "naver" ? 100 : 100;
                  this.form[channel].red = channel === "naver" ? 70 : 60;
                  this.form[channel].yellow = channel === "naver" ? 30 : 30;
                  this.form[channel].white = 10;
                } else {
                  this.form[channel].black = channel === "naver" ? 100 : 100;
                  this.form[channel].red = channel === "naver" ? 100 : 100;
                  this.form[channel].yellow = channel === "naver" ? 50 : 30;
                  this.form[channel].white = 10;
                }
              } else if (this[channel + "DiscountType"] === "high") {
                if (this.adsData.local.region === "서울") {
                  this.form[channel].black = channel === "naver" ? 100 : 100;
                  this.form[channel].red = channel === "naver" ? 100 : 70;
                  this.form[channel].yellow = channel === "naver" ? 30 : 30;
                  this.form[channel].white = 10;
                } else {
                  this.form[channel].black = channel === "naver" ? 100 : 100;
                  this.form[channel].red = channel === "naver" ? 100 : 100;
                  this.form[channel].yellow = channel === "naver" ? 50 : 30;
                  this.form[channel].white = 10;
                }
              } else {
                this.form[channel].black = this.form[channel].black || 100;
                this.form[channel].red = this.form[channel].red || 60;
                this.form[channel].yellow = this.form[channel].yellow || 30;
                this.form[channel].white = this.form[channel].white || 10;
              }
              if (!this.isLimitation) {
                delete this.form[channel].limitation;
              }
              this.form[channel].number = this[channel + '_limit'] ? 0 : this[channel + '_number'];

              this.form[channel].blackPrice = this.form[channel].redPrice = this.form[channel].yellowPrice
                  = this.form[channel].whitePrice = this.form[channel].diamondPrice = this.channel.maxPrice;

              this.$forceUpdate();
            } else {
              // delete this.form[channel];
              this.form[channel] = undefined;
            }
          });

          console.log(this.form);

          // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
          this.form.step = 4;
          if (this.adsData.step > 2) {
            this.$emit("updateEvent", this.form);
          }
        }
        return;
      });
    },
    initData() {
      // this.channel.startDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      // this.dateFormatted = this.formatDate(this.channel.startDate);
      if (this.adsData.naver) {
        this.type.push("naver");
        this.form.naver = this.adsData.naver;
      }
      if (this.adsData.insta) {
        this.type.push("insta");
        this.form.insta = this.adsData.insta;
      }
      if (this.adsData["requiredKeywords" || []].length > 0) {
        this.requiredKeyword = this.adsData.requiredKeywords[0];
      }
    },
  },
  created() {
    this.initData();
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/scss/custom";

.checkbox {
  border: 1px solid;
  border-radius: 8px;
  max-width: 130px;
  height: 40px;
  margin-top: 0px;
  padding-right: 12px;
}

.radio_group {
  margin-top: 0px;
}

.radio {
  border: 1px solid;
  border-radius: 8px;
  max-width: 130px;
  height: 40px;
  margin-top: 0px;
  width: 130px;
}

.radio.v-item--active {
  border-color: var(--rouge-color);
}

.line {
  border-bottom: 1px solid #c2c2c2;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}

.label {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

.plus_btn {
  width: 40px;
  height: 40px !important;
  min-width: 40px !important;
  color: white;
  padding: 0 !important;
}
</style>
<style lang="scss">
.v-checkbox-white__container {
  .v-input--selection-controls__input {
    width: 21px !important;
    height: 21px !important;
  }
}

.white-text-field {
  .v-input__slot {
    background-color: #f5f5f5 !important;
  }
}
</style>
